import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout/Layout'

import Article from '../components/Article/Article'
import SEO from '../components/SEO/SEO'
import { LocaleTypes } from '../data/locales'

interface ArticleTemplateProps {
  data: {
    article: {
      title: string
      subtitle: string
      fullWidth: boolean | null
      hero: boolean | null
      heroTextColor: string | null
      heroImg: {
        localFile: {
          publicURL: string
        }
      } | null
      content: Array<{
        body: {
          childMdx: {
            body: any
            excerpt: string
            timeToRead: string
            tableOfContents: any
          }
        }
        tabTitle: string
      }>
      updatedAt: string
    }
  }
  pathContext: {
    id: string
    locale: LocaleTypes
    country: string
  }
  location: Location
}

const ArticleTemplate: React.FC<ArticleTemplateProps> = ({data, pathContext, location}) => {
  return (
    <Layout country={pathContext.country} location={location} locale={pathContext.locale}>
      <SEO title={data.article.title} description={data.article.subtitle} />

      <Article
        title={data.article.title}
        subtitle={data.article.subtitle}
        contents={data.article.content}
        fullWidth={data.article.fullWidth ? data.article.fullWidth : false}
        country={pathContext.country}
        hero={data.article.hero ? data.article.hero : undefined}
        heroTextColor={data.article.heroTextColor ? data.article.heroTextColor : undefined}
        heroImg={data.article.heroImg ? data.article.heroImg.localFile.publicURL : undefined}
      />
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query($id: String!, $locale: String!, $localeDateFormat: String!) {
    article: contentfulArticle(id: {eq: $id}, node_locale: {eq: $locale}) {
      title
      subtitle
      fullWidth
      hero
      heroTextColor
      heroImg {
        localFile {
          publicURL
        }
      }
      content {
        body {
          childMdx {
            body
            excerpt
            timeToRead
            tableOfContents(maxDepth: 4)
          }
        }
        updatedAt(formatString: $localeDateFormat, locale: $locale)
        tabTitle
      }
    }
  }
`
